<template>
  <form @submit.prevent='submitForm'>
    <div class='form-control'>
      <label for='email'>Your e-mail</label>
      <input type='email' id='email' v-model.trim='email'/>
    </div>
    <div class='form-control'>
      <label for='message'>Message</label>
      <textarea id='message' v-model.trim='message'></textarea>
    </div>
    <p class='errors' v-if='!formIsValid'>Please enter valid email and not-empty message.</p>
    <div class='actions'>
      <base-button type='submit'>Send message</base-button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ContactCoach',
  data() {
    return {
      email: '',
      message: '',
      formIsValid: true,
    }
  },
  methods: {
    submitForm() {
      this.formIsValid = true;
      if (this.email === '' || !this.email.includes('@') || this.message === ''){
        this.formIsValid = false;
        return;
      }
      this.$store.dispatch('requests/contactCoach', {
        coachId: this.$route.params.id,
        email: this.email,
        message: this.message
      });
      this.$router.replace('/coaches');
    }
  }
};
</script>

<style scoped>
form {
  margin: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 1rem;
}

.form-control {
  margin: .5rem 0 1.5rem;
}

label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
}

input,
textarea {
  transition: .3s;
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: .75rem;
  min-width: 100%;
  max-width: 100%;
}

input:focus,
textarea:focus {
  background-color: #f6f6f7;
  outline: none;
  border-color: #3d008d;
}

.errors {
  font-weight: bold;
  color: red;
}

.actions {
  text-align: center;
}
</style>
